import * as t from 'io-ts'
import { IntlShape } from 'react-intl'
import * as url from 'url'
import * as qs from 'querystring'

export { injectIntl } from 'react-intl'

/*
 * Can't re-export these types because of a stupid webpack warning:
 * export 'InjectedIntl' (reexported as 'Intl') was not found in 'react-intl'
 */
export type Intl = IntlShape

export const Locale = t.union([t.literal('de'), t.literal('fr')])
export type Locale = t.TypeOf<typeof Locale>

export const enumLocales: Locale[] = Locale.types.map((x) => x.value)

export type LocaleMatcher<T> = { [k in Locale]: T }
export const caseLocale = <T>(m: LocaleMatcher<T>, locale: Locale): T => m[locale]

export const intlFormats = {
  number: {
    percent1: {
      style: 'percent',
      minimumFractionDigits: 0,
      maximumFractionDigits: 1,
    },
  },
} as const

/**
 * Returns the original URL with the ?locale query added to it. This is used
 * in the backoffice when generating the iframe URLs.
 *
 * > urlWithLocale(intl, "https://example.com") == "https://example.com?locale=…"
 */
export function urlWithLocale(intl: Intl, originalUrl: string): string {
  const parsedUrl = url.parse(originalUrl, true)
  parsedUrl.query.locale = intl.locale.split('-')[0]
  parsedUrl.search = qs.stringify(parsedUrl.query)
  return url.format(parsedUrl)
}
