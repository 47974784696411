import * as upstream from '@ta-interaktiv/parse-hostname'
import { _casePublication } from '../domain/publication'

export interface ParsedResults {
  projectDomain: string
  publicationDomain: string
  publicationName: string
}

/**
 * Wrapper around the upstream version of parseHostname (from the
 * "@ta-interaktiv/parse-hostname" package). It adds support to the tamedia
 * ballot dashboard staging environment domain.
 *
 * If you access the project through the "tamedia-ballot-dashboard-dev.firebaseapp.com"
 * domain, it will behave like localhost, ie. it will treat it as "tagesanzeiger.ch".
 */
export const parseHostname = (hostname?: string) => {
  if (typeof window === 'undefined') {
    return {
      projectDomain: 'interaktiv.tagesanzeiger.ch',
      publicationDomain: 'tagesanzeiger.ch',
      publicationName: 'tagesanzeiger',
    }
  }

  const res = upstream.parseHostname(hostname)

  if (res.projectDomain.match(/\.(firebaseapp\.com|web\.app)$/)) {
    return {
      ...res,
      projectDomain: 'interaktiv.tagesanzeiger.ch',
      publicationDomain: 'tagesanzeiger.ch',
      publicationName: 'tagesanzeiger',
    }
  } else {
    return res
  }
}

/**
 * Returns the domain of the interaktiv. / interactif. website.
 *
 * Example: "interaktiv.tagesanzeiger.ch"
 */
export const publicationInteractiveDomain = ({ publicationName, publicationDomain }: ParsedResults) => {
  const subDomain = _casePublication(
    {
      '24heures': 'interactif',
      bazonline: 'interaktiv',
      bernerzeitung: 'interaktiv',
      derbund: 'interaktiv',
      lematin: 'interactif',
      tagesanzeiger: 'interaktiv',
      tdg: 'interactif',
      firebaseapp: 'interaktiv',
      web: 'interaktiv',
      landbote: 'interaktiv',
      zsz: 'interaktiv',
      zuonline: 'interaktiv',
    },
    publicationName
  )

  return `${subDomain || 'interaktiv'}.${publicationDomain}`
}

export const ballotEmbedDomain = () => {
  if (typeof window === 'undefined' || document.referrer === '') {
    return ''
  } else if (document.referrer.match(/http:\/\/(localhost|127.0.0.1).*/)) {
    return window.location.origin
  } else {
    const { publicationName, publicationDomain } = parseHostname(document.referrer)

    const subDomain = _casePublication(
      {
        '24heures': 'votations',
        bazonline: 'abstimmungen',
        bernerzeitung: 'abstimmungen',
        derbund: 'abstimmungen',
        lematin: 'votations',
        tagesanzeiger: 'abstimmungen',
        tdg: 'votations',
        firebaseapp: 'abstimmungen',
        web: 'abstimmungen',
        landbote: 'abstimmungen',
        zsz: 'abstimmungen',
        zuonline: 'abstimmungen',
      },
      publicationName
    )

    return `//${subDomain || 'abstimmungen'}.${publicationDomain}`
  }
}
