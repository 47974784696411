import * as t from 'io-ts'
import { IntlShape } from 'react-intl'

/* ----------------------------------------------------------------------------
 * Percent
 *
 * Values in range from 0% to 100%, available with guaranteed two decimal
 * precision.
 *
 * Internal representation: Integer in the range [0 .. 10'000].
 *
 * When converting from/to ordinary TypeScript numbers, the values are rescaled
 * to [0..1], to be compatible with d3.
 */

export interface PercentBrand {
  readonly Percent: unique symbol
}

export const Percent = t.brand(t.Int, (n): n is t.Branded<t.Int, PercentBrand> => n >= 0 && n <= 10000, 'Percent')

export type Percent = t.TypeOf<typeof Percent>

/**
 * Return the percent value as number in range [0..1]
 */
export const unPercent = (p: Percent): number => p / 10000

/**
 * Create a new Percent from a number in range [0..1]. If the input
 * is outside of that range, a warning is printed to the console.
 */
export const mkPercent = (n: number): Percent => {
  if (n < 0 || n > 1) {
    console.warn(`mkPercent: value outside of range ${n}`)
  }

  return Math.round(n * 10000) as Percent
}

export function formatPercent1(intl: IntlShape, value: Percent): string {
  return intl.formatNumber(unPercent(value), { format: 'percent1' }).replace(/\s+%$/, '\u202F%')
}

/* ----------------------------------------------------------------------------
 * PercentDifference
 *
 * Values in range from -100% to +100%. Is used to express the difference
 * between two 'Percent' values.
 *
 * Internal representation analog to 'Percent'. When converting to/from
 * ordinary number, values are rescaled to [-1 .. +1].
 */

export interface PercentDifferenceBrand {
  readonly PercentDifference: unique symbol
}

export const PercentDifference = t.brand(
  t.Int,
  (n): n is t.Branded<t.Int, PercentDifferenceBrand> => n >= -10000 && n <= 10000,
  'PercentDifference'
)

export type PercentDifference = t.TypeOf<typeof PercentDifference>

/**
 * Return the value as number in range [-1..+1]
 */
export const unPercentDifference = (p: PercentDifference): number => p / 10000

/**
 * Create a new PercentDifference from a number in range [-1..1]. If the input
 * is outside of that range, a warning is printed to the console.
 */
export const mkPercentDifference = (n: number): PercentDifference => {
  if (n < -1 || n > 1) {
    console.warn(`mkPercentDifference: value outside of range ${n}`)
  }

  return Math.round(n * 10000) as PercentDifference
}

export function formatPercentDifference1(intl: IntlShape, value: PercentDifference): string {
  if (Math.round(value / 10) === 0) {
    return '0\u202F%'
  } else {
    const s = intl.formatNumber(unPercentDifference(value), { format: 'percent1' }).replace('%', '\u202F%').replace(/\s/g, "")
    return value <= 0 ? s : `+${s}`
  }
}
