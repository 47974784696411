import { History } from 'history'
import * as t from 'io-ts'

/**
 * Parse the query params from a 'History' object into the given type.
 *
 * @example
 * // Define an interface describing the query params.
 * const QueryParams = t.interface({
 *   id: t.string,
 *   focus: t.union([t.undefined, t.string])
 * })
 *
 * // Parse the params and do something sensible both in case of
 * // success and failure.
 * parseQueryParams(history, QueryParams).fold(
 *   () => 'fail',
 *   ({ id, focus }) => `id: ${id}, focus: ${focus || 'no focus'}`,
 * )
 */
 export const parseQueryParams = <T>(history: History, type: t.Decoder<unknown, T>): t.Validation<T> => {
   const params = new URLSearchParams(history.location.search);
   return type.decode(Object.fromEntries(params.entries()));
 }
