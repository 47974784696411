import { History } from 'history'
import * as React from 'react'
import { hot } from 'react-hot-loader/root'
import { WrappedComponentProps } from 'react-intl'
import Loadable from 'react-loadable'
import { Route, RouteProps, Router, Switch } from 'react-router-dom'
import { ScrollManager } from 'react-scroll-manager'
import { injectIntl } from '../../../domain'
import { Env } from '../../../lib/env'
import { CenteredLoader } from '../../../materials/index'
import { EnableColorScheme, Runtime } from '../../runtime'
import * as R from './routes'
import * as firestore from 'firebase/firestore'

interface Props {
  history: History
  db: firestore.Firestore
}

export const App = hot(({ history, db }: Props) => (
  <Runtime history={history} appVariant="ballot" appId="standalone">
    <EnableColorScheme />
    <Inner history={history} db={db} />
  </Runtime>
))

const Inner = injectIntl<'intl', WrappedComponentProps & Props>(({ history, db, intl }) => {
  const render = (page: keyof typeof pages) => (routeProps: RouteProps) => {
    const Page = pages[page] as any
    return <Page history={history} db={db} intl={intl} {...routeProps} />
  }

  return (
    <Env.Provider value={{ history, firebaseApp: db.app, db }}>
      <ScrollManager history={history}>
        <Router history={history}>
          <Switch>
            <Route path={R.dashboard} exact render={render('dashboard')} />
            <Route path={R.popularVoteFederal(':popularVoteId')} exact render={render('popularVoteFederal')} />
            <Route path={R.popularVoteCantonal(':popularVoteId')} exact render={render('popularVoteCantonal')} />
            <Route
              path={R.federalBallot(':popularVoteId', ':federalBallotId')}
              exact
              render={render('federalBallot')}
            />
            <Route
              path={R.federalBallotMunicipalities(':popularVoteId', ':federalBallotId')}
              exact
              render={render('federalBallotMunicipalities')}
            />
            <Route
              path={R.cantonalBallot(':popularVoteId', ':cantonId', ':ballotId')}
              exact
              render={render('cantonalBallot')}
            />
            <Route
              path={R.cantonalBallotMunicipalities(':popularVoteId', ':cantonId', ':ballotId')}
              exact
              render={render('cantonalBallotMunicipalities')}
            />
            <Route path="*" render={render('notFound')} />
          </Switch>
        </Router>
      </ScrollManager>
    </Env.Provider>
  )
})

const pages = {
  dashboard: Loadable({
    loader: () => import(/* webpackChunkName: "dashboard" */ './pages/dashboard'),
    loading: () => <CenteredLoader />,
  }),
  popularVoteFederal: Loadable({
    loader: () => import(/* webpackChunkName: "popular-vote" */ './pages/popular-vote-federal'),
    loading: () => <CenteredLoader />,
  }),
  popularVoteCantonal: Loadable({
    loader: () => import(/* webpackChunkName: "popular-vote" */ './pages/popular-vote-cantonal'),
    loading: () => <CenteredLoader />,
  }),
  federalBallot: Loadable({
    loader: () => import(/* webpackChunkName: "federal-ballot" */ './pages/federal-ballot'),
    loading: () => <CenteredLoader />,
  }),
  federalBallotMunicipalities: Loadable({
    loader: () =>
      import(/* webpackChunkName: "federal-ballot-municipalities" */ './pages/federal-ballot-municipalities'),
    loading: () => <CenteredLoader />,
  }),
  cantonalBallotMunicipalities: Loadable({
    loader: () =>
      import(/* webpackChunkName: "federal-ballot-municipalities" */ './pages/cantonal-ballot-municipalities'),
    loading: () => <CenteredLoader />,
  }),
  cantonalBallot: Loadable({
    loader: () => import(/* webpackChunkName: "cantonal-ballot" */ './pages/cantonal-ballot'),
    loading: () => <CenteredLoader />,
  }),
  notFound: Loadable({
    loader: () => import(/* webpackChunkName: "404" */ '../../../pages/404'),
    loading: () => <CenteredLoader />,
  }),
}
