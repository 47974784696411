import { intlFormats, Locale } from '@/domain/intl'
import { color } from '@/materials/index'
import { css, Global, SerializedStyles } from '@emotion/core'
import * as Sentry from '@sentry/browser'
import { COLORS, ThemeProvider } from '@ta-interaktiv/react-components'
import { History } from 'history'
import * as t from 'io-ts'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import { defineMessages, IntlProvider } from 'react-intl'
import { colorSchemeStyles } from '../theme'
import { Blur, DiagonalStripePattern, DropShadow } from '../components/ballot-map/patterns'
import { _casePublication } from '../domain'
import { AppVariant } from '../domain/appVariant'
import { parseQueryParams, useEnv } from '../lib/index'
import { parseHostname } from '../lib/parseHostname'
import messagesDe from '../locale/de/messages.json'
import messagesFr from '../locale/fr/messages.json'
import '../prelude'
import * as F from 'fp-ts'

import { ColorModeOverrideContext } from '../contexts/ColorModeOverrideContext'

const LocaleQueryParams = t.interface({
  locale: Locale,
})

function localeForPublication(publicationName: string): Locale {
  return (
    _casePublication<Locale>(
      {
        '24heures': 'fr',
        bazonline: 'de',
        bernerzeitung: 'de',
        derbund: 'de',
        lematin: 'fr',
        tagesanzeiger: 'de',
        tdg: 'fr',
        firebaseapp: 'de',
        web: 'de',
        landbote: 'de',
        zsz: 'de',
        zuonline: 'de',
      },
      publicationName,
    ) || 'de'
  )
}

// Expose app configuration to the developer console
if (typeof window !== 'undefined') {
  ;(window as any).app = {
    localeDe() {
      window.postMessage({ locale: 'de' })
    },
    localeFr() {
      window.postMessage({ locale: 'fr' })
    },
  }
}

function messagesForLocale(locale: Locale) {
  switch (locale) {
    case 'de':
      return messagesDe
    case 'fr':
      return messagesFr
  }
}

// -----------------------------------------------------------------------------
// Page Head component

const appMessages = {
  docs: null,
  election: defineMessages({
    appTitle: {
      id: 'eWy6YT7',
      defaultMessage: 'Eidgenössische Wahlen',
      description: 'meta page title (Election)',
    },
    appDescription: {
      id: 'iiSV7fi',
      defaultMessage:
        'Die Schweiz stimmt ab. Alle Trends, Hochrechnungen und Resultate live in der neuen Abstimmungszentrale.',
      description: 'meta page description (Election)',
    },
    shareTitle: {
      id: 'DUXBGsn',
      defaultMessage: 'Eidgenössische Wahlen',
      description: 'meta share title (Election)',
    },
    shareDescription: {
      id: 'VMYmgkJ',
      defaultMessage: 'Alle Trends, Hochrechnungen und Resultate live in der neuen Abstimmungszentrale.',
      description: 'meta share description (Election)',
    },
  }),
  ballot: defineMessages({
    appTitle: {
      id: 'head.appTitle',
      defaultMessage: 'Abstimmungen Schweiz',
    },
    appDescription: {
      id: 'head.appDescription',
      defaultMessage:
        'Die Schweiz stimmt ab. Alle Trends, Hochrechnungen und Resultate live in der neuen Abstimmungszentrale.',
    },
    shareTitle: {
      id: 'head.shareTitle',
      defaultMessage: 'Die Schweiz stimmt ab',
    },
    shareDescription: {
      id: 'head.shareDescription',
      defaultMessage: 'Alle Trends, Hochrechnungen und Resultate live in der neuen Abstimmungszentrale.',
    },
  }),
}

interface Props {
  locale: string
  localeTag: string
  appVariant: AppVariant
}

const PageHead = ({ locale, localeTag, appVariant }: Props) => {
  const { intl } = useEnv()
  const host = parseHostname()

  const headDescriptors = appMessages[appVariant]

  /** Not needed for catalog */
  if (!headDescriptors) return null

  return (
    <Helmet>
      <html lang={`${locale}-${localeTag}`} />
      <title>{intl.formatMessage(headDescriptors.appTitle)}</title>
      <meta name="description" content={intl.formatMessage(headDescriptors.appDescription)} />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:title" content={intl.formatMessage(headDescriptors.shareTitle)} />
      <meta property="twitter:description" content={intl.formatMessage(headDescriptors.shareDescription)} />
      <meta property="og:title" content={intl.formatMessage(headDescriptors.shareTitle)} />
      <meta property="og:description" content={intl.formatMessage(headDescriptors.shareDescription)} />
      <meta
        property="og:image"
        content={`https://${host.publicationDomain}/${appVariant}-dashboard-default-share-image.jpg`}
      />
    </Helmet>
  )
}

// -----------------------------------------------------------------------------
// Runtime component

export interface RuntimeProps {
  history: History
  children: React.ReactNode
  appVariant: AppVariant
  appId: 'standalone' | 'embed' | 'backoffice' | 'docs'
}

export type RuntimeState = Readonly<{
  locale: Locale
}>

/**
 * The <Runtime> component is a wrapper that should be placed around the whole
 * application. It provides global contexts (react-intl, theme), global CSS
 * styles and variables, SVG patterns.
 *
 * In a Next.js app, this is something you'd put into pages/_app.
 */
export const Runtime = ({ history, children, appVariant, appId }: RuntimeProps) => {
  const host = parseHostname()

  const [{ locale, localeTag }, setLocale] = React.useState(() => {
    const localeFromQuery = F.function.pipe(
      parseQueryParams(history, LocaleQueryParams),
      F.either.fold(
        () => F.option.none,
        (x) => F.option.some(x.locale),
      ),
    )

    const localeFromLocalStorage = () => {
      try {
        return F.function.pipe(
          Locale.decode(localStorage.getItem('locale')),
          F.either.fold(() => F.option.none, F.option.some),
        )
      } catch (e) {
        return F.option.none
      }
    }

    const initialLocale = F.function.pipe(
      localeFromQuery,
      F.option.alt(() => (appId === 'backoffice' ? localeFromLocalStorage() : F.option.none)),
      F.option.getOrElseW(() => localeForPublication(host.publicationName)),
    )

    return {
      locale: initialLocale,
      localeTag: 'CH',
    }
  })

  React.useEffect(() => {
    const onChangeLocale = ({ locale }: { locale: Locale }) => {
      setLocale({ locale, localeTag })

      try {
        localStorage.setItem('locale', locale)
      } catch (e) {
        // Ignore
      }
    }

    function onMessage(ev: MessageEvent<any>) {
      if (typeof ev.data.locale === 'string') {
        onChangeLocale({ locale: ev.data.locale })
      }
    }

    window.addEventListener('message', onMessage)
    return () => {
      removeEventListener('message', onMessage)
    }
  }, [setLocale, localeTag])

  const [colorModeOverride, setColorModeOverride] = React.useState<null | string>(null)
  React.useEffect(() => {
    function onMessage(ev: MessageEvent<any>) {
      if (ev.data.type === 'colorMode') {
        setColorModeOverride(ev.data.mode)
      }
    }

    window.addEventListener('message', onMessage)
    return () => {
      removeEventListener('message', onMessage)
    }
  }, [])

  return (
    <>
      <Global styles={[globalStyles, tenantStyles[host.publicationName]]} />
      <ColorModeOverrideContext.Provider value={colorModeOverride}>
        <svg style={{ height: 0 }}>
          <defs>
            <DiagonalStripePattern id="diagonal-stripe-yea" stroke={color.yea} />
            <DiagonalStripePattern id="diagonal-stripe-nay" stroke={color.nay} />
            <DiagonalStripePattern id="diagonal-stripe-yeaSecondary" stroke={color.yeaSecondary} />
            <DiagonalStripePattern id="diagonal-stripe-naySecondary" stroke={color.naySecondary} />
            <DiagonalStripePattern id="diagonal-stripe-inProgress" stroke={color.grey['500']} />
            <DropShadow id="dropshadow" />
            <Blur id="blur" />
          </defs>
        </svg>
        <IntlProvider
          key={locale /* Trigger render on locale change. See https://github.com/yahoo/react-intl/issues/243 */}
          locale={`${locale}-${localeTag}`}
          formats={intlFormats}
          defaultFormats={intlFormats}
          messages={messagesForLocale(locale)}
        >
          <ThemeProvider preventDarkmode>
            <ErrorBoundary locale={locale}>
              <PageHead locale={locale} localeTag={localeTag} appVariant={appVariant} />
              {children}
            </ErrorBoundary>
          </ThemeProvider>
        </IntlProvider>
      </ColorModeOverrideContext.Provider>
    </>
  )
}

class ErrorBoundary extends React.PureComponent<{ locale: Locale }> {
  state = { error: undefined, eventId: undefined }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({ error })
    Sentry.withScope((scope) => {
      scope.setExtras({ ...errorInfo })
      const eventId = Sentry.captureException(error)
      this.setState({ eventId })
    })
  }

  render() {
    if (this.state.error) {
      /*
       * If we ever decide to have a really nice "The application has crashed" webpage,
       * consider adding this feedback button to it.
       *
       * See https://docs.sentry.io/enriching-error-data/user-feedback.
       *
       * <a onClick={() => Sentry.showReportDialog({ eventId: this.state.eventId })}>Report feedback</a>
       */

      return <div>Error.</div>
    } else {
      return this.props.children
    }
  }
}

export const globalStyles = css`
  /**
   * Font faces
   */
  @import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,700');
  @import url('https://interaktiv.tagesanzeiger.ch/static/themes/abstimmungen.css');

  /**
   * Reset
   * Based on normalize.css, sanitize.css, minireset.css
   */

  *,
  ::before,
  ::after {
    box-sizing: inherit;
  }

  :root {
    --ui-font-stack: 'Source Sans Pro', sans-serif;

    --color-primary: magenta;

    --color-white: #fff;

    --gray-1: #121212;
    --gray-2: #555;
    --gray-3: #7d7d7d;
    --gray-4: #d4d4d4;
    --gray-5: #e9e9e9;
    --gray-6: #f4f4f4;

    --brandblue-main: #202346;

    --brandblue-bright-1: #474a66;
    --brandblue-bright-2: #6f7187;
    --brandblue-bright-3: #9698a8;
    --brandblue-bright-4: #c8c8d1;
    --brandblue-bright-5: #f0f0f2;

    /*
     * Default color scheme (if no preference) is light.
     */
    ${colorSchemeStyles.light};
  }

  html {
    box-sizing: border-box;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }

  body {
    margin: 0;

    color: var(--text-color);
    background-color: var(--site-background);
  }

  /* Certain ad-scripts (that are injected by the parent window) insert an img
   * tag into the body which messes up the DimensionWatcher. Hide those elements
   * so that they don't affect the dimensions of the iframe. */
  body > img {
    display: none;
  }

  /* Align with TagesAnzeiger */
  a,
  a:hover {
    text-decoration: none;
  }

  article,
  aside,
  footer,
  header,
  nav,
  section,
  figcaption,
  figure,
  main,
  canvas,
  svg {
    display: block;
  }

  svg:not(:root) {
    overflow: hidden;
  }
  img {
    border-style: none;
    height: auto;
    max-width: 100%;
  }
  iframe {
    border: 0;
  }

  ul,
  ol {
    list-style: none;
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    margin: 0;
  }
  button,
  input {
    overflow: visible;
  }
  button,
  select {
    text-transform: none;
  }
  textarea {
    overflow: auto;
    resize: vertical;
  }

  a,
  area,
  button,
  input,
  label,
  select,
  summary,
  textarea,
  [tabindex] {
    -ms-touch-action: manipulation;
    touch-action: manipulation;
  }
`

const tenantStyles: Record<string, undefined | SerializedStyles> = Object.fromEntries(
  Object.entries(COLORS.tenants).map(([tenant]) => [
    tenant,
    css`
      :root {
        --color-primary: var(--color-tenants-${tenant});
      }
    `,
  ]),
)

/**
 * This component injects global CSS that makes :root CSS variables respect
 * (prefers-color-scheme).
 *
 * This is not enabled globally because not all pages have been tested in dark
 * mode yet. Once all pages support dark mode, we can get rid of this component
 * and merge the CSS tweaks into the global styles defined by <Runtime>.
 */
export function EnableColorScheme() {
  /*
   * Color mode can be manually overridden by the user. The value is expected to
   * be either 'dark' or 'light', but since it's set via incoming message we
   * relax the type to any string.
   */
  const [colorModeOverride, setColorModeOverride] = React.useState<null | string>(null)

  React.useEffect(() => {
    function onMessage(ev: MessageEvent<any>) {
      /*
       * We're not doing any security checks of the message, since we don't know
       * in which sites we may be embedded. Also, setting color mode is not
       * security sensitive.
       *
       * https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage#security_concerns
       */

      if (ev.data.type === 'colorMode') {
        console.log('Received color mode override:', ev.data.mode)
        setColorModeOverride(ev.data.mode)
      }
    }

    window.addEventListener('message', onMessage)
    return () => {
      removeEventListener('message', onMessage)
    }
  }, [])

  return (
    <Global
      styles={[
        css`
          @media (prefers-color-scheme: dark) {
            :root {
              ${colorSchemeStyles.dark}
            }
          }
        `,
        colorModeOverride &&
          colorModeOverride in colorSchemeStyles &&
          css`
            body {
              ${colorSchemeStyles[colorModeOverride]}
            }
          `,
      ]}
    />
  )
}
